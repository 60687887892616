import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { Row } from 'react-bootstrap';
import {useState} from 'react';
import ReactSelect from 'react-select';
import './contactForm.css';

export default function ContactForm() {
  const [sent, setSent] = useState(false)
  const defaultValues = {
    message: 'No option given'
  }
  const { register, handleSubmit, control, reset,formState: { errors }, } = useForm({defaultValues});

  const onSubmit = data =>
  axios.post('https://sqmerchantservices.com:8443/messages',data).then(res =>{
    if(res.status === 200){
      reset()
      setSent(true)
    }
  });
  
   // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <div className="form-cont">
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
    <Row>
    
    <h1 className='form-title'>Contact Form</h1>
    {sent ? <h2>Contact email sent! We will get back to you shortly.</h2> : null}
    </Row>
    <Row>
    <label htmlFor="name">
      Full Name:<br/>
      {/* register your input into the hook by invoking the "register" function */}
    
      <input className="input" {...register("name",{required: true})} /><br/>
      </label>
      {errors.name && errors.name.type === "required" && (
        <span>Name required!</span>
      )}
    </Row>
    Email:<br/>
    <label htmlFor='email'>
      <input type='email' className="input" {...register("email", {required: true})}/><br/>
    </label>
    {errors.email && errors.email.type === "required" && (
      <span>Email required!</span>
    )}
    <label htmlFor='phone'>
      Contact Number:<br/>
      <input className="input" {...register("phone", {required:true})} /><br/>
    </label>
    {errors.phone && errors.phone.type === "required" && (
      <span>Phone number is required!</span>
    )}
      <label htmlFor='company'>
      Company Name:<br/>
      <input className="input" {...register("company", {required:true})} /><br/>
    </label>
    {errors.company && errors.company.type === "required" && (
      <span>Company name required!</span>
    )}
    <label htmlFor="message">
    What can we help you with?<br/>
      <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <ReactSelect
                className='drop-menu-form'
                isClearable
                {...field}
                options={[
                  { value: "Payment processing for my business", label: "Payment Processing" },
                  { value: "Online integration for payments", label: "Accept payments online" },
                  { value: "ATM services", label: "ATM services" },
                  { value: "Cash Discounting", label: "Cash Discounting(Edge Processing)" },
                  { value: "Non-Profit Processing", label: "Non-Profit Processing"},
                  { value: "Text Message Giving", label: "Text Message Giving"},
                  { value: "Clink Funnels", label: "Click Funnels"},
                  { value: "Mobile Payments", label: "Mobile Payments"},
                  { value:"Employment", label: "Employment"},
                ]}
              />
            )}
          />
      
    </label><br/>
      <button className="form-btn" type="submit">Submit</button>
    </form></div>
  );
}